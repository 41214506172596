* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
body {
  background-color: #fff;
  font-family: Poppins, Roboto-Regular, Roboto;
}
.inner {
  margin: 0 auto;
  max-width: 1200px;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
}
@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Bold;
  src: url(./fonts/Poppins-Bold.ttf) format("truetype");
}

